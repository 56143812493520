.connectio-row-button  {
    margin-right: 5px;
    margin-bottom: 5px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.server-address {
    word-wrap: break-word;
    width: 150px;
}

.server-description {
    word-wrap: break-word;
    width: 350px;
}

.small-server-address {
    font-size: 80%;
}

.home-container {
    max-width: 800px;
}

.modal-body {
    user-select: text;
}

.footer {
    height: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: lightblue;
    user-select: text;
  }