.error-message {
    font-size: 12px;
    color: var(--red-600);
    width: 400px;
    margin-top: 0.25rem;
    color: red;
}

.error-message::before {
    content: "❌ ";
    font-size: 10px;
}

.label {
    font-weight: bold;
    display: flex;
}

.input {
    width: 100%;
}

.button {
    margin-right: 5px;
}

.checkbox {
    margin-right: 5px;
    margin-top:auto;
    margin-bottom:auto;
}

