.sliding-menu {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    pointer-events: auto;
    filter: drop-shadow(0 6px 10px rgba(0,0,0,.6));
    transition-duration: 200ms;
    transition-property: left,right;
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
    z-index: 9999;
  }

  .hide-element {
    display: none !important;
  }

  .sliding-menu:not(.menu-on-right) {
    left: 0;
    flex-direction: row-reverse;
  }

  .sliding-menu.menu-on-right {
    right: 0;
    flex-direction: row;
  }
  
  .sliding-menu.sliding-menu-closed:not(.menu-on-right) {
    left: -200px;
  }
  
  .sliding-menu.sliding-menu-closed.menu-on-right {
    right: -200px;
  }
  
  .open-menu-tab {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--page-bg-color);
    align-self: center;
    display: flex;
    user-select: none;
    cursor: pointer;
    margin-left: -25px;
    margin-right: 0;
  }
  
  .menu-on-right .open-menu-tab {
    margin-left: 0px;
    margin-right: -25px;
  }
  
  .open-menu-tab-arrow {
    display: flex;
    font-size: 35px;
    align-self: center;
    justify-content: center;
    margin-left: 16px;
    transform: rotate(0);
    transition-duration: 280ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  }
  
  .menu-on-right.open-menu-tab-arrow {
    transform: rotate(180);
  }
  
  .sliding-menu-closed .open-menu-tab-arrow {
    transform: rotate(180deg);
  }
  
  .menu-on-right.sliding-menu-closed .open-menu-tab-arrow {
    transform: rotate(0);
  }
  
  .menu-on-right .open-menu-tab-arrow {
    margin-left: 0px;
    margin-right: 16px;
    transform: rotate(180deg);
  }
  
  .sliding-menu-pane {
    width: 200px;
    background-color: var(--page-bg-color);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    position: relative;
  }
  
  .sliding-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: max(calc(env(safe-area-inset-left,0px) - 16px),0px);
    padding-right: 0;
    padding-bottom: 16px;
  }
  
  .menu-on-right .sliding-menu-header {
    flex-direction: row-reverse;
  }
  
  .menu-control-children {
    position: relative;
    top: -12px;
    font-size: 24px;
    display: inline-block;
    color: var(--text-color);
  }
  
  .menu-control {
    user-select: none;
    transition: background .3s;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    flex-shrink: 0;
    height: 48px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 48px;
    z-index: 0;
    color: rgba(0,0,0,.54);
    fill: rgba(0,0,0,.54);
  }
  
  .closed-menu-tab-container-1 {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    filter: drop-shadow(6px 6px 10px rgba(0,0,0,.8));
    pointer-events: none;
    z-index: 9999;
  }
  
  .closed-menu-tab-container-1:not(.menu-on-right)  {
    left: 0px;
  }

  .menu-on-right.closed-menu-tab-container-1 {
    right: 0px;
  }
  
  .closed-menu-tab-container-2 {
    display: flex;
    margin-left: -25px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 20px;
    transition-duration: 200ms;
    opacity: .5;
    transition-property: margin,opacity;
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
    pointer-events: auto;
    align-self: center;
  }
  
  .menu-on-right .closed-menu-tab-container-2 {
    margin-right: -25px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 20px;
  }

  .closed-menu-tab-container-2:hover {
    opacity: 1.0;
  }
  
  :not(.menu-on-right) .closed-menu-tab-container-2:hover {
    margin-left: -20px;
  }

  .menu-on-right .closed-menu-tab-container-2:hover {
    margin-right: -20px;
  }
  
  .closed-menu-tab-container-3 {
    position: relative;
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--page-bg-color);
    align-self: center;
    user-select: none;
    cursor: pointer;
  }

  .closed-menu-tab-arrow {
    display: flex;
    font-size: 35px;
    align-self: center;
    justify-content: center;
    margin-left: 20px;
  }

  .menu-on-right .closed-menu-tab-arrow {
    margin-left: 0px;
    margin-right: 20px;
    transform: rotate(180deg);
  }

  .sliding-menu-body {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background: white;
    outline: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 0 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  .menu-button {
    padding: 6px 44px 6px 30px;
    white-space: nowrap;
  }

  .menu-button:hover {
    background-color: #EEE;
  }

  li.selected {
    background-image: url(assets/tick.webp);
    background-position: left center;
    background-repeat: no-repeat;
  }

  .menu-separator {
   border-top: 1px solid #EBEBEB;
   margin-top: 9px;
   margin-bottom: 10px;
   padding: 0;
  }

  .menu-control-button {
    position: relative;
    top: 50%
  }

  .menu-button-text {
    fontSize: 14px;
    marginLeft: 20px;
    marginTop: 10px;
    color: black;
    textAlign: left
  }


