
/* Bump-scrolling is currently implemented by adjusting the margins, which is
 * easier to implement with "position: fixed". In full-screen mode there are
 * no scroll-bars, so the advantages of flex-box layout to achieve centering
 * (ie, the DOM is easier to measure to determine when scroll-bars are needed)
 * don't apply */
 .fullscreen #session-mode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  /* Override full-height class until connected, otherwise it takes up 100% of
   * the window height in addition to the home-screen UI. */
  #app:not(.connected) #session-mode {
    height: auto;
  }
inFullScreenrequest-credentials-dialog
  .full-height {
    height: 100%;
  }

 .mouse-cursor-overlay-container {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
}

.mouse-cursor-overlay-image {
    position: relative;
}

.request-credentials-dialog label{
    margin: 5px 10px 5px 0;
    float: left;
    clear: left;
    width: 100px;
    text-align: right;
  }

 .request-credentials-dialog  input {
    vertical-align: middle;

  margin: 5px 10px 5px 10px;
  float: left;

  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;

  color: #333;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0
  }

 .request-credentials-dialog input:hover  {
    border: 1px solid #b9b9b9;
    border-top: 1px solid #a0a0a0;
    box-shadow: inset 0px 1px 2px rgba(0,0,0,0.1);
  }

 .request-credentials-dialog input:focus {
    box-shadow: inset 0px 1px 2px rgba(0,0,0,0.3);
    outline: none;
    border: 1px solid #4d90fe;
}

.full-width {
    width: 100%;
}

.client-container {
    position: relative;
    clip-path: inset(0px);
    line-height: 0;
}

.horizontally-centered {
    display: flex;
    align-items: center;
}

.scroller {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: fixed;
}

.vertically-centered {
    display: flex;
    flex-direction: column;
    height: 100%
}
